import React, { useState } from "react";
import "./patientforgotpassword2.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OTPInput from "react-otp-input";
import { Box } from "@mui/material";
import CustomButton from "../../../components/CustomButton/custom-button";
import { baseURL } from "../../../constants/const";
import CustomTextField from "../../../components/CustomTextField";

const patientforgotpassword2 = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(null);
    const [fetchEmail] = useState(sessionStorage.getItem("EmailForgotPassword"));
    // const [otp, setOtp] = useState('');

    const verifyOTP = async () => {
        console.log("fetchEmail : ", fetchEmail);
        try {
            const reponse = await axios.post(
                `${baseURL}/sec/auth/verifyEmail`,
                JSON.stringify({
                    email: fetchEmail,
                    activation_code: otp,
                }),
            );
            console.log(reponse);
            sessionStorage.setItem("forgotpasswordotp", otp);
            navigate("/patientforgotpassword3");
        } catch (error) {
            console.log(error);
        }
    };

    // from here

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate("/patientforgotpassword3");
    };
    console.log("otp", otp);
    return (
        <Box className="register-photo">
            <Box className="form-container"
              sx={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                minHeight: "100vh" // Ensures it centers vertically
            }}
            >
                <div className="image-holder"></div>
                    <div className="forgotPassword">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <img src="images/logo.png" alt="Logo"/>
                            </div>

                            <h2>
                                <strong>Please enter OTP</strong>
                            </h2>

                            <div>
                                <p>The OTP has been sent to - {fetchEmail} </p>
                            </div>
                        </Box>
                        {/* <CustomTextField
                            id={"standard-helperText1"}
                            type={'number'}
                            label={"Email"}
                            defaultValue={otp}
                            helperText={"Mobile or Email"}
                            // isValid
                            onChange={(event) => setOtp(event.target.value)}
                            textcss={{
                                width: "22.5em",
                                height: "56px",
                                WebkitAppearance : "none"
                            }}
                        /> */}

                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputStyle={{
                                // width: "10%",
                                // height: "3rem",
                                backgroundColor: "#ffff",
                                borderRadius: "10px",
                                fontSize: "1.7rem",
                            }}
                            containerStyle={{ justifyContent: "center"  }}
                            renderSeparator={<span style={{ marginLeft: "2%" }}></span>}
                            renderInput={(props) => <input {...props} />}
                        />
                        <CustomButton
                            label={"Continue"}
                            isTransaprent={false}
                            isDisabled={false}
                            isElevated={false}
                            handleClick={verifyOTP}
                            buttonCss={{
                                width: "19em",
                                padding: "8px 16px",
                                marginTop: "3%",
                                borderRadius: "100px",
                            }}
                        />
                        <div className="resendCode">
                            <p>Resend Code</p>
                        </div>
                    </div>
                
            </Box>
        </Box>
    );
};

export default patientforgotpassword2;
