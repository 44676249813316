/* eslint-disable import/order */
/* eslint-disable keyword-spacing */
/* eslint-disable space-before-blocks */
/* eslint-disable prettier/prettier */
// import React from 'react'
import React, { useEffect, useState } from "react";
import "./patientsignup.scss";
import { Box, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton/custom-button";
import axios from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { numberRegex } from "../../../constants/const";
import { baseURL } from "../../../constants/const";

const patientsignup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    // State for who is registering
    const [module, setModule] = useState();
    const [moduleName, setModuleName] = useState();
    const [submitButtonEnable, setSubmitButtonEnable] = useState(true);
    const typeOfUser = localStorage.getItem("signUp");
    const navigateToLogin =
        typeOfUser === "super_admin"
            ? "/superadminlogin"
            : typeOfUser === "hcf_admin"
            ? "/hcfadminlogin"
            : typeOfUser === "doctor"
            ? "/doctorlogin"
            : typeOfUser === "diagnostic_center"
            ? "/diagnostcenterlogin"
            : typeOfUser === "patient"
            ? "/patientlogin"
            : typeOfUser === "clinic"
            ? "/diagnostcliniclogin"
            : null;

    const roleID =
        typeOfUser === "super_admin"
            ? 1
            : typeOfUser === "hcf_admin"
            ? 2
            : typeOfUser === "doctor"
            ? 3
            : typeOfUser === "diagnostic_center"
            ? 4
            : typeOfUser === "patient"
            ? 5
            : typeOfUser === "clinic"
            ? 6
            : null;
    // console.log(`Role ID : ${roleID}`);
    // Need to change the role id based on the user type to register the user
    const [data, setData] = useState({
        email: null,
        mobile: null,
        password: null,
        role_id: roleID,
    });

    // const [isloading , setIsloading] = useState(false);
    // console.log("Send Data",JSON.stringify(data));
    // useNavigate hook for navigate

    const navigate = useNavigate();
    console.log("data : ", data);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${baseURL}/sec/auth/register`,
                JSON.stringify(data),
            );
            console.log("Response Received", response);
            alert(response);
            Cookies.set("email", data?.email);
            navigate("/patientverification");
        } catch (error) {
            console.log(error);
            console.log(error?.response?.request?.status);
            // if (error?.response?.request?.status === 403) {
            //     alert(error?.response?.data?.error);
            // }

            // navigate("/patientverification");
        }
    };

    useEffect(() => {
        const moduleType = localStorage.getItem("signUp");
        setModule(moduleType);
        const NameOfModule =
            moduleType === "patient"
                ? "Patient"
                : moduleType === "doctor"
                ? "Doctor"
                : moduleType === "super_admin"
                ? "Super Admin"
                : moduleType === "diagnostic_center"
                ? "Diagnostic Center"
                : moduleType === "clinic"
                ? "Clinic"
                : moduleType === "hcf_admin"
                ? "HCF Admin"
                : null;

        setModuleName(NameOfModule);
    }, []);

    // useEffect(() => {
    //     if(isloading === true){
    //         fetchData();
    //     }
    // } ,[isloading]);

    // from here

    const handleSubmit = (e) => {
        // setData({
        //     email: 'dsdasd',
        //     mobile: '321321',
        //     password: 'dsada',
        //     role_id: 5,
        // });
        // setIsloading(!isloading);
        e.preventDefault();
        console.log("CLicked");
        fetchData();
        // navigate("/patientverification");
    };

    return (
        <div className="register-photo">
            <Box className="form-container">
                <div className="image-holder"></div>

                <Box className="component-library ">
                <Box sx={{display:"flex" , flexDirection:"column",justifyContent:"center", alignItems:"center"}}>
                    <div className="logo1">
                        <img src="images/logo.png" alt="Logo"/>
                    </div>

                    <h2 className="text-center">
                        <strong>{`${moduleName} `} Sign Up</strong>
                    </h2>
                    </Box>
                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Mobile Number"}
                                        defaultValue={data?.mobile}
                                        // helperText={"Mobile Number"}
                                        // isValid
                                        // eslint-disable-next-line no-undef
                                        onChange={(event) => {
                                            if (event?.target?.value.match(numberRegex)) {
                                                console.log("Number");
                                                const copy = {
                                                    ...data,
                                                    mobile: event?.target?.value,
                                                };
                                                setData(copy);
                                                // setHelperTextMessage(true);
                                            } else {
                                                console.log("not a number ");
                                                // setHelperTextMessage(false);
                                            }

                                            // setMobile(event.target.value)
                                        }}
                                        // onChange={(event) => setMobile(event.target.value) }
                                        textcss={{
                                            width: "19.5em",
                                            // height: "56px",
                                        }}
                                    />

                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Email Address"}
                                        defaultValue={data.email}
                                        // helperText={"Email Address"}
                                        // isValid
                                        onChange={(event) => {
                                            const copy = { ...data, email: event?.target?.value };
                                            setData(copy);
                                            // setEmail(event.target.value)
                                        }}
                                        textcss={{
                                            width: "19.5em",
                                            // height: "56px",
                                        }}
                                    />

                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Enter Password"}
                                        defaultValue={password}
                                        helperText={"Password"}
                                        type={showPassword ? "password" : "text"}
                                        // isValid
                                        // onInput={(event) => {
                                        //     // let copy = {...data , password : event?.target?.value}
                                        //     // setData(copy)
                                        //     setPassword(event.target.value);
                                        // }}

                                        onInput={(event) => {
                                            setPassword(event.target.value);

                                            if (data.password === event.target.value) {
                                                console.log("password matched");
                                                setSubmitButtonEnable(false);
                                                // setData({ ...data, password: confirmPassword });
                                            } else {
                                                console.log("password does not match");
                                                setSubmitButtonEnable(true);
                                            }
                                        }}
                                        inputType={showPassword ? "password" : "text"}
                                        textcss={{
                                            width: "19.5em",
                                            // height: "56px",
                                        }}
                                        rightIcon={
                                            showPassword ? (
                                                <VisibilityIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setShowPassword(false)}
                                                />
                                            ) : (
                                                <VisibilityOffIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setShowPassword(true)}
                                                />
                                            )
                                        }
                                    />

                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Confirm Password"}
                                        defaultValue={data.password}
                                        helperText={"Confirm Password"}
                                        type={showPasswordConfirm ? "password" : "text"}
                                        // isValid
                                        onInput={(event) => {
                                            setConfirmPassword(event.target.value);
                                            setData({ ...data, password: confirmPassword });

                                            if (password === event.target.value) {
                                                console.log("password matched");
                                                setSubmitButtonEnable(false);
                                                setData({ ...data, password: event.target.value });
                                            } else {
                                                console.log("password does not match");
                                                setSubmitButtonEnable(true);
                                            }
                                        }}
                                        // onChange={(event) => {
                                        //                         setConfirmPassword(event.target.value)
                                        //                         setData({...data , password : confirmPassword});

                                        //                         if(password === confirmPassword){
                                        //                             console.log("password matched");
                                        //                         }
                                        //                         else{
                                        //                             console.log("password does not match");
                                        //                         }
                                        //                     }
                                        //             }
                                        inputType={confirmPassword ? "password" : "text"}
                                        textcss={{
                                            width: "19.5em",
                                            // height: "56px",
                                        }}
                                        rightIcon={
                                            showPasswordConfirm ? (
                                                <VisibilityIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setShowPasswordConfirm(false)}
                                                />
                                            ) : (
                                                <VisibilityOffIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => setShowPasswordConfirm(true)}
                                                />
                                            )
                                        }
                                    />
                               
                        <CustomButton
                            label={"Continue"}
                            isTransaprent={false}
                            isDisabled={submitButtonEnable}
                            isElevated={false}
                            handleClick={handleSubmit}
                            buttonCss={{
                                width: "22em",
                                // height: "3.5em",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "100px",
                                marginTop: "35px",
                            }}
                        />
                    <div className="login">
                        I have an account &nbsp;
                        <Link to={navigateToLogin} className="link">
                            Log In
                        </Link>
                        </div>
                </Box>
            </Box>
        </div>
    );
};

export default patientsignup;
