import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import "./adminsignup.scss";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { TextField } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import ClassicFrame from "../../../constants/DrImages/Undraw.png";
import ImageFrame from "../../../constants/logos/hcf_admin_logo.png";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomSnackBar from "../../../components/CustomSnackBar";
import axiosInstance from "../../../config/axiosInstance";
import CustomRadioButton from "../../../components/CustomRadioButton";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Checkbox, FormControlLabel } from "@mui/material";

const steps = ["", ""];

const AdminSignUp = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [value, setValue] = useState([null, null]);
    const [flagToSendDoctorData, setFlagToSendDoctorData] = useState(false);
    const [updateUserData, setUpdateUserData] = useState({
        email: localStorage.getItem("hcfadmin_Email"),
        hcf_name: null,
        reg_no: null,
        category_id: null,
        service_time_from: null,
        service_time_to: null,
        service_day_from: null,
        service_day_to: null,
        lab_department_id: [],
        state_reg_date: null,
        diag_indian_reg_no: null,
    });
    const [updatedUserSuccesfully, setUpdatedUserSuccesfully] = useState("");
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [hcfCategory, setHcfCategory] = useState("Clinic");
    const [hcfItems] = useState(["Clinic", "Diagnostic Center", "Both"]);
    const [departments, setDepartments] = useState([]);

    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        fetchDepartmentName();
    }, []);

    console.log("user data : ", updateUserData);

    useEffect(() => {
        if (flagToSendDoctorData) {
            PostUserData();
        }
    }, [flagToSendDoctorData]);

    const PostUserData = async () => {
        setFlagToSendDoctorData(false);
        try {
            const response = await axiosInstance.post(
                "/sec/auth/updateProfile",
                JSON.stringify(updateUserData),
            );
            setUpdatedUserSuccesfully("Profile Completed 🙂");
            console.log("send data succesfully : ", response);
            setShowSnackBar(true);
            setFlagToSendDoctorData(false);
            handleNext();
        } catch (err) {
            console.log("Error sending data", err);
            setShowSnackBar(false);
            setFlagToSendDoctorData(false);
        }
    };
    const handleDateRangeChange = (newValue) => {
        setUpdateUserData({
            ...updateUserData,
            service_day_from: `${newValue[0]?.$y}-${newValue[0]?.$M + 1}-${newValue[0]?.$D}`,
            service_day_to: `${newValue[1]?.$y}-${newValue[1]?.$M + 1}-${newValue[1]?.$D}`,
        });
        // checkFields(data);
    };

    const fetchDepartmentName = async () => {
        try {
            const response = await axiosInstance.get("/sec/labDepartments");
            const departmentData = response?.data?.response || [];
            setDepartments(departmentData);
        } catch (err) {
            console.error("Error fetching department names:", err);
        }
    };

    useEffect(() => {
        fetchDepartmentName();
    }, []);

    // Handle department selection
    const handleCheckboxChange = (id) => {
        setUpdateUserData((prevData) => {
            const updatedLabIds = prevData.lab_department_id.includes(id)
                ? prevData.lab_department_id.filter((labId) => labId !== id)
                : [...prevData.lab_department_id, id];

            return { ...prevData, lab_department_id: updatedLabIds };
        });
    };

    const categoryMap = {
        Clinic: 1,
        "Diagnostic Center": 2,
        Both: 3,
    };
    return (
        <>
            <CustomSnackBar
                isOpen={showSnackBar}
                actionLabel={""}
                // handleAction={() => setShowSnack(false)}
                message={updatedUserSuccesfully}
                type="success"
            />
            <Box sx={{ width: "100%" }}>
                <div className="FrameBox1">
                    <Box
                        // sx={{ borderRadius: "8px", width: "100%", height: "100%" }}
                        component={"img"}
                        src={ImageFrame}
                    ></Box>
                </div>

                <div className="step-back">
                    <div className="back-btn">
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1, color: "red" }}
                        >
                            Back
                        </Button>
                    </div>
                    <div className="Stepper">
                        <Stepper
                            activeStep={activeStep}
                            style={{
                                width: "700px",
                            }}
                        >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                </div>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Box sx={{ width: "100%" }}>
                            <div className="imge-cont">
                                <div className="card-cont1">
                                    <div className="card1">
                                        <div className="last-image">
                                            <Box
                                                sx={{ width: "222px", height: "252px" }}
                                                component={"img"}
                                                src={ClassicFrame}
                                            ></Box>
                                        </div>
                                        <div className="greetings1">
                                            <Typography
                                                style={{
                                                    color: "#313033",
                                                    fontFamily: "poppins",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                Your choice to register with us fills us with
                                                immense gratitude
                                            </Typography>
                                        </div>
                                        <div className="note1">
                                            <Typography
                                                style={{
                                                    color: "#939094",
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "21px",
                                                    letterSpacing: "0.07px",
                                                }}
                                            >
                                                Proceed to the login page to embark upon thy journey
                                                henceforth
                                            </Typography>
                                        </div>
                                        <div className="done-btn1">
                                            <CustomButton
                                                label="Proceed to Login, where your adventure awaits"
                                                handleClick={() => navigate("/hcfadminlogin")}
                                                buttonCss={{
                                                    width: "270px",
                                                    borderRadius: "20px",
                                                }}
                                            >
                                                Done
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            {/* Step {activeStep + 1} */}
                            {activeStep === 0 ? (
                                <>
                                    <Box>
                                        <div className="mainBox1">
                                            <Box sx={{ width: "40%" }}>
                                                <div className="heading1">
                                                    <Typography
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "25px",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            lineHeight: "30px",
                                                        }}
                                                    >
                                                        HCF Information
                                                    </Typography>
                                                </div>

                                                <CustomRadioButton
                                                    label={""}
                                                    handleChange={(event) => {
                                                        const selectedCategory = event.target.value;
                                                        console.log(
                                                            "Selected Category: ",
                                                            selectedCategory,
                                                        );

                                                        setHcfCategory(selectedCategory);
                                                        setUpdateUserData((prevData) => ({
                                                            ...prevData,
                                                            category_id:
                                                                categoryMap[selectedCategory], // Map category name to ID
                                                        }));
                                                    }}
                                                    radioGroupCss={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                    }}
                                                    value={hcfCategory}
                                                    items={["Clinic", "Diagnostic Center", "Both"]}
                                                />

                                                <div className="other-fields1">
                                                    <TextField
                                                        label="Business Name"
                                                        variant="standard"
                                                        style={{
                                                            width: "100%",
                                                            color: "#787579",
                                                        }}
                                                        onInput={(event) => {
                                                            setUpdateUserData({
                                                                ...updateUserData,
                                                                hcf_name: event.target.value,
                                                            });
                                                        }}
                                                        value={updateUserData?.hcf_name}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="other-fields1">
                                                    <TextField
                                                        label="Registration No"
                                                        variant="standard"
                                                        style={{
                                                            width: "100%",
                                                            color: "#787579",
                                                        }}
                                                        onInput={(event) => {
                                                            setUpdateUserData({
                                                                ...updateUserData,
                                                                reg_no: event.target.value,
                                                            });
                                                        }}
                                                        value={updateUserData?.reg_no}
                                                        required={true}
                                                    ></TextField>
                                                </div>
                                                <div className="other-fields1">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DemoContainer components={["DatePicker"]}>
                                                            <DatePicker
                                                                label="Reg. Date"
                                                                onChange={(newValue) => {
                                                                    setUpdateUserData({
                                                                        ...updateUserData,
                                                                        state_reg_date: `${
                                                                            newValue?.$y
                                                                        }-${
                                                                            Number(newValue?.$M) + 1
                                                                        }-${newValue?.$D}`,
                                                                    });
                                                                }}
                                                                // DOB : `${newValue?.$y}-${Number(newValue?.$M) + 1}-${newValue?.$D}`,
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                {hcfCategory !== "Clinic" && (
                                                    <div className="other-fields1">
                                                        <TextField
                                                            label="Diagnostic License No"
                                                            variant="standard"
                                                            style={{
                                                                width: "100%",
                                                                color: "#787579",
                                                            }}
                                                            onInput={(event) => {
                                                                setUpdateUserData({
                                                                    ...updateUserData,
                                                                    diag_indian_reg_no:
                                                                        event.target.value,
                                                                });
                                                            }}
                                                            value={
                                                                updateUserData?.diag_indian_reg_no
                                                            }
                                                            required={false}
                                                        />
                                                    </div>
                                                )}
                                            </Box>
                                            <div className="sve-btn">
                                                <CustomButton
                                                    handleClick={handleNext}
                                                    label="Next"
                                                    buttonCss={{
                                                        width: "30%",
                                                    }}
                                                    isDisabled={
                                                        updateUserData.hcf_name != null &&
                                                        updateUserData.reg_no != null &&
                                                        updateUserData.state_reg_date != null
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {activeStep === steps.length - 1
                                                        ? "Finish"
                                                        : "Next"}
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : activeStep === 1 ? (
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <div className="mainBox1">
                                            <Box sx={{ width: "40%" }}>
                                                <div className="heading1">
                                                    <Typography
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "25px",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            lineHeight: "30px",
                                                        }}
                                                    >
                                                        Service Details
                                                    </Typography>
                                                </div>
                                                <div className="Text-fields1">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DateRangePicker
                                                            disablePast
                                                            value={value}
                                                            onChange={(newValue) => {
                                                                handleDateRangeChange(newValue);
                                                            }}
                                                            renderInput={(FromProps, ToProps) => (
                                                                <React.Fragment>
                                                                    <TextField
                                                                        {...FromProps}
                                                                        variant="standard"
                                                                    />
                                                                    <Box sx={{ mx: 2 }}> to </Box>
                                                                    <TextField
                                                                        {...ToProps}
                                                                        variant="standard"
                                                                    />
                                                                </React.Fragment>
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>

                                                <div className="Text-fields1">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                gap: "16px",
                                                            }}
                                                        >
                                                            <TimePicker
                                                                label="Start Time"
                                                                value={
                                                                    updateUserData?.start_time ||
                                                                    null
                                                                }
                                                                onChange={(newValue) => {
                                                                    setUpdateUserData({
                                                                        ...updateUserData,
                                                                        service_time_from: newValue,
                                                                    });
                                                                }}
                                                                renderInput={(props) => (
                                                                    <TextField
                                                                        {...props}
                                                                        variant="standard"
                                                                        style={{
                                                                            width: "48%",
                                                                            color: "#787579",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <TimePicker
                                                                label="End Time"
                                                                value={
                                                                    updateUserData?.end_time || null
                                                                }
                                                                onChange={(newValue) => {
                                                                    setUpdateUserData({
                                                                        ...updateUserData,
                                                                        service_time_to: newValue,
                                                                    });
                                                                }}
                                                                renderInput={(props) => (
                                                                    <TextField
                                                                        {...props}
                                                                        variant="standard"
                                                                        style={{
                                                                            width: "48%",
                                                                            color: "#787579",
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="gender1">
                                                    <Box>
                                                        <h3>Select Lab Departments</h3>
                                                        {departments.map((department) => (
                                                            <FormControlLabel
                                                                key={department.lab_department_id}
                                                                control={
                                                                    <Checkbox
                                                                        checked={updateUserData.lab_department_id.includes(
                                                                            department.lab_department_id,
                                                                        )}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                department.lab_department_id,
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    department.lab_department_name
                                                                }
                                                            />
                                                        ))}
                                                        
                                                    </Box>
                                                </div>
                                            </Box>

                                            <div className="sve-btn">
                                                <CustomButton
                                                    handleClick={PostUserData}
                                                    label="Submit"
                                                    buttonCss={{
                                                        width: "30%",
                                                    }}
                                                >
                                                    {activeStep === steps.length - 1
                                                        ? "Finish"
                                                        : "Next"}
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <h1>Completed</h1>
                            )}
                            ;
                        </Typography>
                    </React.Fragment>
                )}
            </Box>
        </>
    );
};

export default AdminSignUp;
