import React, { useState } from "react";
import "./patientloginwithotp2.scss";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton/custom-button";
import OTPInput from "react-otp-input";
import { Box } from "@mui/material";

const patientloginwithotp2 = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(null);

    // from here

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate("/patientdashboard");
    };
    return (
            <div className="register-photo">
                <Box className="form-container">
                    <div className="image-holder"></div>

                    <Box id="contaiern-2" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    {/* <Box sx={{display:"flex" , flexDirection:"column",justifyContent:"center", alignItems:"center"}}> */}
                        <Box id="container-2-inner">
                            <img src="images/logo.png" alt="Logo"/>

                            {/* <h2 className="text-center"> */}
                            <strong style={{ fontSize : "1rem"}} >Please enter OTP</strong>
                            {/* </h2> */}
                            <Box id="otp-box-container"  display="flex" flexDirection="column" alignItems="center">
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputStyle={{
                                        backgroundColor: "#ffff",
                                        borderRadius: "10px",
                                        fontSize: "1.7rem",
                                        // borderColor : "#ffff"
                                    }}
                                    containerStyle={{ justifyContent: "center" }}
                                    renderSeparator={<span style={{ marginLeft: "2%" }}></span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                {/* <div className="otpsent"> */}
                                    <p>The OTP has been sent to - 0123456789 </p>
                                {/* </div> */}
                                {/* </Box> */}
                                <CustomButton
                                    label={"Continue"}
                                    isTransaprent={false}
                                    isDisabled={false}
                                    isElevated={false}
                                    handleClick={handleSubmit}
                                    buttonCss={{
                                        width: "18em",
                                        padding: "8px 16px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "100px",
                                    }}
                                />
                            <div className="resend-otp">Resend Code</div>
                            {/* </div> */}
                            </Box>
                     </Box>
                </Box>
            </Box>
       </div> 
    );
};

export default patientloginwithotp2;

