/* eslint-disable semi */
/* eslint-disable space-before-blocks */
/* eslint-disable prettier/prettier */
// import React from 'react'
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import "./doctorlogin.scss";
import { Box, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthentication } from "../../loginComponent/UserProvider";
import CustomSnackBar from "../../components/CustomSnackBar";
import axiosInstance from "../../config/axiosInstance";
import CustomTextField from "../../components/CustomTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomButton from "../../components/CustomButton";
import { baseURL, emailRegex, passwordRegex } from "../../constants/const";

const DoctorLogin = () => {
    const [showPassword, setShowPassword] = useState(true);
    const [islogin, setIslogin] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showSnack, setShowSnack] = useState(false);
    const regularExpression = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    const [invalidUser, setInvalidUser] = useState(false);
    const [invalidUserMessage, setInvalidUserMessage] = useState("");
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("error");
    const [data, setData] = useState({
        email: null,
        password: null,
        login_with_email: null,
        // mobile: null,
        role_id: 3,
    });

    const navigate = useNavigate();
    const { DoctorLogin } = useAuthentication();
    const fetchData = async () => {
        console.log("Entered the fetch data");
        setShowSnack(true);
        // navigate("/skelatonLoader" , {replace : true});
        try {
            // const response = await axiosInstance.post("/sec/auth/login",JSON.stringify(data),{"Accept" : "Application/json"});
            console.log(data);
            const response = await axios.post(`${baseURL}/sec/auth/login`, JSON.stringify(data), {
                Accept: "Application/json",
            });
            console.log("REsponase : ", response);
            setErrorState(false);
            setShowSnack(false);
            if (response?.data?.response?.body === "INCOMPLETE_PROFILE") {
                localStorage.setItem("doctor_suid", response?.data?.response?.suid);
                alert("sign up");
                setInvalidUser(false);
                localStorage.setItem("email", data?.email);
                navigate("/doctorsignup");
            } else if (response?.data?.error === "invalid user") {
                setInvalidUser(true);
                setInvalidUserMessage(response?.data?.error);
            } else if (response?.data?.response?.access_token) {
                localStorage.setItem("email", response?.data?.response?.email);
                localStorage.setItem("access_token", response?.data?.response?.access_token);
                localStorage.setItem("doctor_suid", response?.data?.response?.suid);
                localStorage.setItem("path", "request");
                localStorage.setItem("logged_as", "doctor");
                localStorage.setItem("profile", response?.data?.response?.profile_picture);
                Cookies.set("doctor_uid", response?.data?.response?.suid);
                Cookies.set("token", response?.data?.response?.access_token);
                Cookies.set("doctorEmail", response?.data?.response?.email);
                DoctorLogin(response?.data?.response?.email); // alert("succesfully loggedIn");
                console.log("navigate to doctor");
                setIslogin(false);
                setInvalidUser(false);
                navigate("/doctordashboard", { replace: true });
            } else {
                alert("unable to login");
            }
        } catch (error) {
            console.log(error?.response?.data?.error);
            setErrorState(true);
            setErrorMessage(error?.response?.data?.error);
            setEmail(null);
            setPassword(null);
            setIslogin(false);
        }
    };

    useEffect(() => {
        if (islogin === true) {
            fetchData();
        }
    }, [islogin]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // else if(regularExpression.test(password)){
        //     alert("password should contain atleast one number and one special character");
        //     return false;
        // }
        setData({
            email: email,
            password: password,
            // mobile: "9994483286",
            login_with_email: true,
            role_id: 3, // role id of doctor is 3
        });

        if (email === null && password === null) {
            alert("Email and Password");
            setIslogin(false);
            return false;
        }

        setIslogin(!islogin);
        // fetchData();
        return null;
    };

    return (
        <div className="register-photo">
            <CustomSnackBar
                isOpen={showSnack}
                // actionLabel={"action"}
                // handleAction={() => setShowSnack(true)}
                message={"Please Wait while we are logging you in"}
                type="success"
            />

            <CustomSnackBar isOpen={invalidUser} message={invalidUserMessage} type={"error"} />

            <CustomSnackBar isOpen={invalidUser} message={invalidUserMessage} type={"error"} />

            <CustomSnackBar isOpen={errorState} message={errorMessage} type={"error"} />

            <Box className="form-container">
                <div className="image-holder"></div>

                <Box className="component-library ">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="logo1">
                            <img src="images/logo.png" alt="Logo" />
                        </div>

                        <h2 className="text-center">
                            <strong>Doctor Login</strong>
                        </h2>
                    </Box>
                    <CustomTextField
                        id={"standard-helperText1"}
                        label={"Email"}
                        defaultValue={email}
                        helperText={"Mobile or Email"}
                        // isValid
                        onChange={(event) => setEmail(event.target.value)}
                        textcss={{
                            width: "19.5em",
                            // height: "56px",
                        }}
                    />
                    {/* spacing={10}
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{ marginTop: "2%" }} */}

                    <CustomTextField
                        id={"standard-helperText1"}
                        label={"Password"}
                        defaultValue={password}
                        helperText={"Password"}
                        // isValid
                        onChange={(event) => setPassword(event.target.value)}
                        inputType={"password"}
                        textcss={{
                            width: "19.5em",
                            // height: "56px",
                        }}
                        rightIcon={
                            showPassword ? (
                                <VisibilityIcon onClick={() => setShowPassword(false)} />
                            ) : (
                                <VisibilityOffIcon onClick={() => setShowPassword(true)} />
                            )
                        }
                    />
                    <CustomButton
                        label={"Log In"}
                        isTransaprent={false}
                        isDisabled={false}
                        isElevated={false}
                        handleClick={handleSubmit}
                        buttonCss={{
                            width: "22em",
                            padding: "8px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "100px",
                            marginTop: "35px",
                        }}
                    />

                    <div className="forgotpassword" style={{ fontSize: "small" }}>
                        <Link to="/patientforgotpassword" className="link">
                            Forgot Password
                        </Link>
                    </div>
                    <div className="mobile" style={{ fontSize: "small" }}>
                        <Link to="/patientloginwithotp" className="link">
                            Log In with OTP
                        </Link>
                    </div>
                    <div
                        className="already"
                        style={{
                            display: "inline",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "small",
                        }}
                    >
                        I Don&apos;t have an account &nbsp;
                        <Link to="/patientsignup" className="link">
                            Create Account
                        </Link>
                    </div>
                </Box>
            </Box>
        </div>
    );
};

export default DoctorLogin;
