// import React from 'react'
import React from "react";
import "./patientloginwithotp.scss";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton/custom-button";

const patientloginwithotp = () => {
    // useNavigate hook for navigate
    const navigate = useNavigate();

    // from here

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate("/patientloginwithotp2");
    };
    return (
        <div className="register-photo">
            <Box className="form-container">
                <div className="image-holder"></div>

                <Box className="component-library ">
                <Box sx={{display:"flex" , flexDirection:"column",justifyContent:"center", alignItems:"center"}}>

                {/* <div id="container">
                    <div id="inner-container"> */}
                        <center>
                            <img src="images/logo.png" alt="Logo"/>
                        </center>

                        <h2 className="">
                            <strong style={{ fontSize : "1rem",marginTop:"20px"}}>Please enter mobile no</strong>
                        </h2>
                
                                        <CustomTextField
                                            id={"standard-helperText1"}
                                            label={"Mobile No"}
                                            defaultValue={""}
                                            helperText={""}
                                            isValid
                                            textcss={{
                                                width: "19em",
                                                // height: "56px",
                                            }}
                                        />
                            <CustomButton
                                label={"Continue"}
                                isTransaprent={false}
                                isDisabled={false}
                                isElevated={false}
                                handleClick={handleSubmit}
                                buttonCss={{
                                    width: "22em",
                                    // height: "3.5em",
                                    padding: "8px 16px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "100px",
                                    marginTop: "35px",
                                }}
                            />
                        {/* </div>
                    
                </div> */}
                </Box>
            </Box>
            </Box>
        </div>
    );
};

export default patientloginwithotp;

